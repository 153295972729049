<template>
  <div>
    <div class="pane">
      <div class="title">{{ detail.name }}</div>
      <a-tabs
        v-model="active"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane
          v-for="item in paramTypeList"
          :key="item.value"
          :tab="item.name"
        >
        </a-tab-pane>
      </a-tabs>

      <a-space class="add">
        <a-button type="primary" @click="addText">添加参数</a-button>
      </a-space>
    </div>

    <a-card class="container">
      <a-table
        bordered
        :dataSource="list"
        :pagination="{
          total: list.length,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
            {{ index + 1 }}
          </template>
        </a-table-column>

        <a-table-column title="中文名" data-index="name" />
        <a-table-column title="英文名" data-index="code" />
        <a-table-column title="设计值">
          <template slot-scope="text">
            <!-- <span v-if="String(text.valueType) === '1'"> -->
            {{ text.designValue }}
            <!-- </span> -->
          </template>
        </a-table-column>
        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="bim_value_type"
              :dictValue="String(text.valueClass)"
            />
          </template>
        </a-table-column>
        <a-table-column title="单位" data-index="unit" align="center" />
        <a-table-column title="参考信息" data-index="remark" />
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="editText(text)">编辑</a>
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      :visible="visible"
      :title="type === 'add' ? '添加参数' : '修改参数'"
      @cancel="cancel"
      :footer="null"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="分类">
          <a-select
            placeholder=""
            style="width: 100%"
            v-decorator="[
              'paramType',
              {
                initialValue: activeParam.paramType,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
          >
            <a-select-option
              v-for="item in paramTypeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="中文名">
          <a-input
            v-decorator="[
              'name',
              {
                initialValue: activeParam.name,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="英文名">
          <a-input
            v-decorator="[
              'code',
              {
                initialValue: activeParam.code,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="类型">
          <a-radio-group
            v-decorator="[
              'valueClass',
              {
                initialValue: activeParam.valueClass,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            button-style="solid"
          >
            <a-radio
              v-for="item in valueTypeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item label="设计值">
          <a-input-number
            v-decorator="[
              'designValue',
              {
                initialValue: activeParam.designValue,
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="数值精度">
          <a-input-number
            :min="0"
            :max="16"
            v-decorator="[
              'decimalPrecision',
              {
                initialValue: activeParam.decimalPrecision,
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="单位">
          <a-input
            v-decorator="[
              'unit',
              {
                initialValue: activeParam.unit,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="备注">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'remark',
              {
                initialValue: activeParam.remark,
              },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail } from "@/api/parameterization/book";
import { add, edit, remove } from "@/api/parameterization/param";
import { mapGetters } from "vuex";
export default {
  name: "paramBookDetail",
  mixins: [watermark],

  data() {
    return {
      active: "",
      id: null,
      detail: {},

      visible: false,
      form: this.$form.createForm(this),
      saveLoading: false,
      type: "add",
      activeParam: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    paramTypeList() {
      return this.findDataDict("bim_param_type");
    },
    valueTypeList() {
      return this.findDataDict("bim_value_type");
    },

    list() {
      if (Array.isArray(this.detail.bimItemList)) {
        if (this.active) {
          return this.detail.bimItemList.filter(
            (item) => item.paramType === this.active
          );
        } else {
          return this.detail.bimItemList;
        }
      } else {
        return [];
      }
    },
  },

  activated() {
    this.active = this.paramTypeList[0].value;

    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      fetchDetail({ id: this.id }).then((res) => {
        this.detail = res;
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;

          if (this.type === "add") {
            add({
              pid: this.detail.id,
              ...values,
            })
              .then(() => {
                this.cancel();
                this.getDetail();
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            edit({
              ...this.activeParam,
              ...values,
            })
              .then(() => {
                this.cancel();
                this.getDetail();
              })
              .finally(() => {
                this.saveLoading = false;
              });
          }
        }
      });
    },
    cancel() {
      this.visible = false;
    },

    addText() {
      this.visible = true;
      this.type = "add";
      this.activeParam = {};
    },

    editText(text) {
      this.visible = true;
      this.type = "edit";
      this.activeParam = text;
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "是否删除？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          remove({ id: text.id }).then(() => {
            that.getDetail();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px 0;
  position: relative;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }

  .add {
    position: absolute;
    top: 32px;
    right: 32px;
  }
}

.container {
  background-color: #fff;
  padding: 16px;
}
</style>
